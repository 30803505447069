const requestToken = (g) => {
    g.$http({
        url: '/auth/refresh-token',
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + g.$cookies.get('token')
        }
    })
        .then(res => {
            g.$cookies.set('token', res.data.token)
            if (g.$route.name == 'login')
                g.$router.replace({ name: 'home' })
        })
        .catch(err => {
            if (g.$route.name != 'login') {
                if (g.$route.name != null) {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Session`,
                        variant: 'danger',
                        solid: true,
                    })

                    g.$cookies.remove('token')
                    localStorage.removeItem('userData')
                    // g.$router.replace({ name: 'login'})
                    window.location.reload()
                }
                else if (err.response) {
                    if (err.response.data == "Sesi Telah Berakhir") {
                        g.$cookies.remove('token')
                        localStorage.removeItem('userData')
                        window.location.reload()
                    }
                }
            }
        })
}

export default {
    refreshToken: (g) => {
        requestToken(g);
        return setInterval(() => {
            requestToken(g);
        }, 20 * 60 * 1000 - 500); // 20 menit - 0.5 detik
    }
}