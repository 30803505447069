export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Manajemen',
    action: 'read',
    resource: 'mjmn',
    icon: 'FileTextIcon',
    children: [
      {
        action: 'read',
        resource: 'mjmn-pengguna',
        title: 'Pengguna',
        route: 'mjmn-pengguna',
      },
    ],
  },
  {
    title: 'Master',
    icon: 'DatabaseIcon',
    action: 'read',
    resource: 'mst',
    children: [
      {
        action: 'read',
        resource: 'mst-daerah',
        title: 'Daerah',
        icon: 'MapPinIcon',
        children: [
          {
            title: 'Provinsi',
            route: 'mstDaerahProvinsi',
            action: 'read',
            resource: 'mst-daerah-provinsi',
          },
          {
            title: 'Kota / Kabupaten',
            route: 'mstDaerahKotaKabupaten',
            action: 'read',
            resource: 'mst-daerah-kotaKabupaten',
          },
          {
            title: 'Kecamatan',
            route: 'mstDaerahKecamatan',
            action: 'read',
            resource: 'mst-daerah-kecamatan',
          },
          {
            title: 'Kelurahan',
            route: 'mstDaerahKelurahan',
            action: 'read',
            resource: 'mst-daerah-kelurahan',
          }
        ]
      },
      {
        title: 'Peran',
        route: 'mstPeran',
        action: 'read',
        resource: 'mst-peran',
      },
      {
        title: 'Fiksasi',
        route: 'mstFiksasi',
        action: 'read',
        resource: 'mst-fiksasi',
      },
      {
        title: 'Layanan',
        route: 'mstLayanan',
        action: 'read',
        resource: 'mst-layanan',
      },
      {
        title: 'Lokasi Asal Jaringan',
        route: 'mstLokasiAsalJaringan',
        action: 'read',
        resource: 'mst-lokasiAsalJaringan',
      },
      {
        title: 'Pembiayaan',
        route: 'mstPembiayaan',
        action: 'read',
        resource: 'mst-pembiayaan',
      },
      {
        title: 'Pengambilan Jaringan',
        route: 'mstPengambilanJaringan',
        action: 'read',
        resource: 'mst-pengambilanJaringan',
      },
      {
        title: 'RS / Klinik',
        route: 'mstRsKlinik',
        action: 'read',
        resource: 'mst-rsKlinik',
      },
      {
        action: 'read',
        resource: 'mst-pemeriksaan',
        title: 'Pemeriksaan',
        icon: 'ActivityIcon',
        children: [
          {
            title: 'Jenis Pemeriksaan',
            route: 'mstPemeriksaanJenisPemeriksaan',
            action: 'read',
            resource: 'mst-pemeriksaan-jenisPemeriksaan',
          },
          {
            title: 'Sediaan',
            route: 'mstPemeriksaanSediaan',
            action: 'read',
            resource: 'mst-pemeriksaan-sediaan',
          },
          {
            title: 'Keterangan Klinis',
            route: 'mstPemeriksaanKeteranganKlinis',
            action: 'read',
            resource: 'mst-pemeriksaan-keteranganKlinis',
          },
          {
            title: 'Diagnosa Klinis',
            route: 'mstPemeriksaanDiagnosaKlinis',
            action: 'read',
            resource: 'mst-pemeriksaan-diagnosaKlinis',
          },
          {
            title: 'Riwayat Lab PA',
            route: 'mstPemeriksaanRiwayatLabPa',
            action: 'read',
            resource: 'mst-pemeriksaan-riwayatLabPa',
          },
          {
            title: 'Makroskopik',
            route: 'mstPemeriksaanMakroskopik',
            action: 'read',
            resource: 'mst-pemeriksaan-makroskopik',
          },
          {
            title: 'Mikroskopik',
            route: 'mstPemeriksaanMikroskopik',
            action: 'read',
            resource: 'mst-pemeriksaan-mikroskopik',
          },
          {
            title: 'Morphology',
            route: 'mstPemeriksaanMorphology',
            action: 'read',
            resource: 'mst-pemeriksaan-morphology',
          },
          {
            title: 'Topography',
            route: 'mstPemeriksaanTopography',
            action: 'read',
            resource: 'mst-pemeriksaan-topography',
          },
          {
            title: 'ICD-O',
            route: 'mstPemeriksaanIcd',
            action: 'read',
            resource: 'mst-pemeriksaan-icd',
          },
        ]
      },
      {
        action: 'read',
        resource: 'mst-tagihan',
        title: 'Tagihan',
        icon: 'FileTextIcon',
        children: [
          {
            title: 'Biaya',
            route: 'mstTagihanBiaya',
            action: 'read',
            resource: 'mst-tagihan-biaya',
          },
          {
            title: 'Bank',
            route: 'mstTagihanBank',
            action: 'read',
            resource: 'mst-tagihan-bank',
          },
        ]
      },
    ],
  },
  {
    title: 'Pemeriksaan',
    icon: 'ActivityIcon',
    action: 'read',
    resource: 'pemeriksaan',
    children: [
      {
        title: 'Patologi Anatomik',
        route: 'pemeriksaanPemeriksaan',
        action: 'read',
        resource: 'pemeriksaan-pemeriksaan',
      },
    ],
  },
  {
    title: 'Tagihan',
    icon: 'FileTextIcon',
    route: 'tagihan',
    action: 'read',
    resource: 'tagihan',
  }
]