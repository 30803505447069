<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{nama}}
            </p>
            <span class="user-status">{{peran}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {ref } from '@vue/composition-api'
import auth from '@/libs/auth'

export default {
  methods: {
    logout(){
      this.$cookies.remove('token')
      localStorage.removeItem('userData')
      this.$ability.update([{
        action: 'read',
        subject: 'Auth',
      }])

      this.$router.replace({ name: 'login'})
    }
  },
  setup(props, context){
    const g = context.root

    var nama = ref('')
    var peran = ref('')

    if(g.$cookies.get('token')){
      clearInterval(g.$store.state.global.idInterval)
      let refreshToken = ref(auth.refreshToken(g))
      g.$store.commit('change', refreshToken.value)

      g.$http({
          url: '/auth/get-data',
          method: 'post',
          headers: {
              Authorization: 'Bearer '+g.$cookies.get('token')
          }
      })
          .then(res => {
              const {namaVal, peranVal, userDataVal} = res.data.data
              nama.value = namaVal
              peran.value = peranVal
              
              localStorage.setItem('userData', JSON.stringify(userDataVal))
              g.$cookies.set('peran', peranVal)
          })
          .catch(err => {
            if(g.$route.name != 'login' && g.$route.name != null){
              let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

              g.$bvToast.toast(showError || 'Something Wrong', {
                  title: `Error Load Table`,
                  variant: 'danger',
                  solid: true,
              })
            }
          })
    }

    return {
      nama, peran
    }
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  metaInfo(){
    return {
      titleTemplate: this.$route.meta.pageTitle+' | %s',
    }
  }
}
</script>
